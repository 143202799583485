import api from "@/api";
import { createActionHelpers } from "vuex-loading";

const { startLoading } = createActionHelpers({ moduleName: "loading" });

export default {
  namespaced: true,

  state: {
    customer: undefined
  },

  getters: {
    activeCustomer: (state, getters, rootState, rootGetters) => {
      const authId = rootState.auth?.user?.id
      if (state.customer?.id === authId) return state.customer
      return state.customer?.relations?.find(({ referredCustomerId }) => referredCustomerId === authId)?.referredCustomer
    },
    isCorporateCustomer: (state, getters, rootState, rootGetters) => (customer) => {
      return customer?.sectorId && !((/PRIVATE_CUSTOMER/i.test(rootGetters['classifiers/classifierById']('sectors', customer.sectorId)?.code)) || (/EMPLOYEES/i.test(rootGetters['classifiers/classifierById']('sectors', customer.sectorId)?.code)))
    },
    customerName: (state, getters, rootState, rootGetters) => ({
      sectorId,
      firstName,
      lastName,
      customerName
    }) =>
      /PrivateCustomer/i.test(
        rootGetters["classifiers/classifierById"]("sectors", sectorId).name
      )
        ? `${firstName} ${lastName}`
        : customerName
  },

  actions: {
    async loadCustomer({ dispatch, commit, rootGetters }) {
      commit("SET_CUSTOMER", { customer: null });
      const { data: customer } = await startLoading(
        dispatch,
        "customer:fetch",
        () => api.getCustomer()
      );
      commit("SET_CUSTOMER", { customer });
    }
  },

  mutations: {
    SET_CUSTOMER(state, { customer }) {
      state.customer = customer;
    }
  }
};
